import * as Sentry from '@sentry/nextjs';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { useState, useContext } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { signIn } from '@api/AuthApi';
import { alertMessage } from 'utils/common';
import { Button } from '@arkestro/arkestro-design-system';
import { UserContext } from 'components/providers/UserProvider/UserProvider';
import RedirectService from 'services/RedirectService';
import PolicyLinks from './PolicyLinks';
import SSOFooter from './SSOFooter';

const LoginForm = () => {
  const { t } = useTranslation('common');
  const user = useContext(UserContext);
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    signIn({ email, password })
      .then(results => {
        setIsSubmitting(false);

        const hasAuthErrors =
          results.response &&
          results.response.data &&
          results.response.data.errors;
        const currentUser = results.data?.current_user;
        const hasDataErrors = !results.data || !currentUser;

        if (hasAuthErrors || hasDataErrors) {
          let errMsg = 'There was a problem signing in.';

          if (hasAuthErrors) {
            errMsg = results.response.data.errors[0];
          } else if (hasDataErrors) {
            const stringMessage = `Email: ${email} - Status: ${results?.response?.status}`;
            const errorResponse = { ...results, email };

            Sentry.captureMessage(`Broken Login Flow - ${stringMessage}`);
            Sentry.captureException(
              new Error('Broken Login Flow', errorResponse)
            );
          }

          alertMessage(errMsg, 'error', 6);

          return;
        }

        // Set the current user after successful sign in.
        user.setUserProperty(
          {
            ...currentUser,
            hasFailedHealthCheck: false,
          },
          () => {
            RedirectService.redirectAuthedUser({ user: currentUser, router });
          }
        );
      })
      .catch(err => {
        setIsSubmitting(false);

        Sentry.captureMessage(`Broken Login Flow (Catch) - ${email}`);
        Sentry.captureException(err);

        alertMessage('There was a problem signing in.', 'error', 6);
      });
  };

  if (!user.hasAttemptedAuth) return null;

  return (
    <div className='vertical-table'>
      <div className='vertical-table-cell'>
        <div className='centered'>
          <div className='logo-container'>
            <Image
              width={180}
              height={46}
              layout='intrinsic'
              className='login-logo'
              src='https://bidops-public.s3.amazonaws.com/assets/logos/logo_white@2x.png'
              alt='Logo'
            />
          </div>
          <div className='login-fields-container sign-in'>
            <div className='title'>Sign In</div>

            <div>
              <form onSubmit={handleSubmit}>
                <label htmlFor='user_email'>{t('general.email')}</label>
                <input
                  autoFocus
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className='textfield login-textfield'
                  type='text'
                  name='user[email]'
                  id='user_email'
                />
                <label htmlFor='user_password'>{t('general.password')}</label>
                <div className='password-container'>
                  <input
                    className='textfield password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    type='password'
                    name='user[password]'
                    id='user_password'
                  />
                </div>
                <div className='password-links'>
                  <div className='left'>
                    <Link href='/forgot_password'>
                      {t('general.reset_password')}
                    </Link>
                  </div>
                  <div className='clear' />
                </div>
                <div className='action'>
                  <Button
                    htmlType='submit'
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    type='primary'
                  >
                    {isSubmitting ? t('general.signing_in') : t('general.sign_in')}
                  </Button>
                </div>
              </form>
            </div>
            <PolicyLinks />
          </div>
          <SSOFooter />
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
