import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@arkestro/arkestro-design-system';

interface Props {
  buttonOnly?: boolean;
}

const SSOFooter = ({ buttonOnly = false }: Props) => {
  const router = useRouter();
  const { t } = useTranslation('common');

  const handleSSOLogin = () => {
    router.push('/sso_login');
  };

  return (
    <>
      <div className='messages is-not-visible' />
      <div className='sso-login-link-container'>
        {!buttonOnly && t('login.using_sso')}
        <div className='p'>
          <Button type='link' onClick={handleSSOLogin}>
            {t('general.sign_in')}
          </Button>
          {t('login.sign_in_part_two')}
        </div>
      </div>
    </>
  );
};

export default SSOFooter;
