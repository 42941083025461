import type { NextPage } from 'next';
import { useEffect } from 'react';
import LoginForm from 'components/Login/LoginForm';
import Head from 'components/shared/Head/Head';

const Login: NextPage = () => {
  useEffect(() => {
    document.body.classList.add('primary-themed-background');
    document.body.classList.add('login-layout');

    return () => {
      document.body.classList.remove('primary-themed-background');
      document.body.classList.remove('login-layout');
      document.body.classList.remove('sso-login');
    };
  });

  return (
    <>
      <Head title='Sign In' />
      <LoginForm />
    </>
  );
};

Login.ignoreLayout = true;
Login.ignoreAuthorization = true;

export default Login;
